import React from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

const TermsConditions = ({ data }) => {
	const terms = data.strapiTerms;
	console.log(terms);
	return (
		<Layout>
			<div className="">
				<GatsbySeo
					title="Terms and Conditions"
					description="Read the booking terms &amp; conditions for your Roots & Routes booking, including securing your booking, visas, insurance, and changes, cancellations &amp; delays."
					// languageAlternates={[
					// 	{
					// 		hrefLang: "en-IN",
					// 		href: "https://www.artofbicycletrips.in/terms-conditions",
					// 	},
					// 	{
					// 		hrefLang: "en-US",
					// 		href: "https://www.artofbicycletrips.com/terms-conditions",
					// 	},
					// 	{
					// 		hrefLang: "en",
					// 		href: "https://www.artofbicycletrips.com/terms-conditions",
					// 	},
					// 	{
					// 		hrefLang: "x-default",
					// 		href: "https://www.artofbicycletrips.com/terms-conditions",
					// 	},
					// ]}
				/>
				<div className="my-20">
					<h1 className="font-bold">Terms and Conditions</h1>
					<h2 className="font-light text-primary max-w-3xl mb-14">
						Here’s everything you need to know about the terms and conditions of
						booking a Roots & Routes trip. Please read through carefully and get in
						touch if you need more detail on any point.
					</h2>

					<ReactMarkdown
						className="prose prose-sm sm:prose"
						children={terms.description}
					/>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query TermsConditionsQuery {
		strapiTerms {
			name
			description
		}
	}
`;

export default TermsConditions;
